



































































































































































































































































































































































































































































































































































































import {Component, Watch} from 'vue-property-decorator';
import SettingsItem from '@/components/SettingsItem.vue';
import Selector from '@/components/Selector.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import NotificationsMixin from '@/components/mixins/Notifications';
import ChatInfoUser from '@/components/ChatInfoUser.vue';
import rfdc from 'rfdc';
import {settingsStore} from '@/store/modules/settings';
import {mixins} from "vue-class-component";
import {AlertSetting, AwaySetting} from "@/domain/model/types";
import DialogAddDefaultBackupContact from '@/components/DialogAddDefaultBackupContact.vue';
const clone = rfdc({proto: true})

@Component({
  name: 'notifications',
  components: {VcSwitch, Selector, SettingsItem, DialogAddDefaultBackupContact, ChatInfoUser}
})
export default class Notifications extends mixins(NotificationsMixin) {
  showDialogAddRecipientContact: boolean = false;

  autoAlert: AlertSetting  = {
    active: true,
    contacts: [],
    emails: [],
    // new fields
    requestNew: true,
    requestIdle: true,
    requestIdleTime: 5 * 60 * 1000,
    requestIdleRepeat: 1,

    chatExternalUnread: true,
    chatExternalUnreadTime: 5 * 60 * 1000,
    chatExternalUnreadRepeat: 1,
    chatExternalIdle: true,
    chatExternalIdleTime: 5 * 60 * 1000,
    chatExternalIdleRepeat: 1,

    chatInternalUnread: true,
    chatInternalUnreadTime: 5 * 60 * 1000,
    chatInternalUnreadRepeat: 1,

    appointmentNew: true,
    appointmentReminder: true,
    appointmentReminderTime: 5 * 60 * 1000,
    appointmentReminderRepeat: 1,

    summaryDailyActivity: true,
    summaryDailyBusiness: true
  }

  disabled: boolean = true
  receiverEmails: any[] = [{email: ''}]
  recipientContacts: any[] = []
  doubleEmail: any = {}

  remindTimes: string[] = [
    '30 min',
    '1 hour',
    '1.5 hour',
    '3 hours'
  ];

  idleTimes: any[] = [
    {name: '5 min', value: 5 * 60 * 1000},
    {name: '10 min', value: 10 * 60 * 1000},
    {name: '15 min', value: 15 * 60 * 1000},
    {name: '20 min', value: 20 * 60 * 1000},
    {name: '25 min', value: 25 * 60 * 1000},
    {name: '30 min', value: 30 * 60 * 1000}
  ];

  repeats:  any[] = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
  ];
  repeat: number = this.repeats[0].value;

  @Watch('autoAlert', {deep: true, immediate: false})
  onAlertChanged(after, before) {
    this.checkDisabled()
  }

  checkDisabled() {
    if (!this.compareChange() && this.emailValidate()) {
      this.disabled = false
      return
    }
    this.disabled = true
  }


  @Watch('receiverEmails', {deep: true})
  onChangeEmails(after) {
    this.doubleEmail = {}
    this.doubleEmailCounter(after)
    this.autoAlert.emails = after.map(item => item.email).filter(item => item.length > 0)
  }


  doubleEmailCounter(items) {
    items.forEach(item => {
      const email = item.email ? item.email : null
      if (email) {
        if (this.doubleEmail.hasOwnProperty(email)) {
          this.doubleEmail[email] = this.doubleEmail[email] + 1
        } else {
          this.doubleEmail[email] = 1
        }
      }
    })
  }

  compareAutoAlert() {
    return JSON.stringify(this.autoAlert) === JSON.stringify(settingsStore.businessSettings?.autoAlert)
  }

  compareChange() {
    return this.compareAutoAlert()
  }

  emailValidate() {
    const form: any = this.$refs.receiverEmailForm
    let validate = true
    this.$nextTick(() => {
      if (form) validate = form.validate()
    })
    return validate
  }

  validateDoubleEmail(v) {
    return !v || this.doubleEmail[v] <= 1 || 'Double email'
  }

  onClearEmail(index) {
    this.receiverEmails.splice(index, 1)
    if (this.receiverEmails.length < 1) {
      this.receiverEmails.push({email: ''})
    }
  }

  onAddEmail() {
    if (this.receiverEmails.length < 5) {
      this.receiverEmails.push({email: ''})
    }
  }

  async onSave() {
    if (!this.compareAutoAlert()) {
      await settingsStore.changeAutoAlerts(clone(this.autoAlert))
    }

    this.showInfo('Notifications was changed')
    this.checkDisabled()
  }

  onDiscard() {
    this.init()
  }

  onRemoveClient(id) {
    this.autoAlert.contacts?.splice(this.autoAlert.contacts.findIndex(item => item.id === id), 1)
  }

  onRecipientContactSelected(contact) {
    let data = {
      id: contact.id,
      uid: contact.associate.id,
      name: contact.name,
      email: contact.email,
      position: contact.position
    }
    if (contact.photoUrl) {
      data['photoUrl'] = contact.photoUrl
    }
    this.autoAlert.contacts?.push(data)
  }

  onAddRecipientContact() {
    this.showDialogAddRecipientContact = true
  }

  init() {
    this.autoAlert = clone(settingsStore.businessSettings?.autoAlert)
    if (Array.isArray(this.autoAlert?.emails) && this.autoAlert!.emails!.length > 0) {
      this.receiverEmails = this.autoAlert!.emails!.map(item => {
        return {email: item}
      }) || [{email: ''}]
      this.doubleEmailCounter(this.receiverEmails)
    }
  }

  created() {
    this.init()
  }
}
